import React, { useState, useRef } from "react";
// import Tippy from "@tippyjs/react";
import { certhubUrl } from "../../config/config";
import { clickOutside } from "../../helpers/utils";
import Link from "next/link";
import Loading from "../Loading";
import Menu from "../../../public/img/profile-dropdown/Circled Menu.svg";
// import KonfHub from '../../../public/img/profile-dropdown/KonfHub.svg'
import QuizHub from "../../../public/img/profile-dropdown/QuizHub.svg";
import CertHub from "../../../public/img/profile-dropdown/CertHub.svg";
const SwitchApplications = ({ className }) => {
    const wrapperRef = useRef("menu");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    clickOutside(wrapperRef, () => {
        setOpen(false);
    });

    return (
        <>
            {loading ? (
                <Loading className={"m-auto"} color="#002e6e" />
            ) : (
                <div
                    className={`switch-container position-relative ml-3 ${className ? className : ""}`}
                >
                    {open ? <span className="arrow-up" /> : ""}

                    <div ref={wrapperRef} className="switch-img-container d-inline-block">
                        <div className="d-flex align-items-center justify-content-center h-100 w-100 position-relative">
                            <img
                                style={{ width: "37px", height: "37px",objectFit:"contain" }}
                                src="https://d2nnrpx7gk2my2.cloudfront.net/files/672c650a97b04e000924da17/Appsicon.svg"
                                alt={"Profile Picture"}
                            />
                            <button
                                onClick={() => setOpen(!open)}
                                className="switch-overlay h-100 w-100 position-absolute"
                            ></button>
                        </div>
                        {open && (
                            <div className="switch-dropDown-container p-2 position-absolute">
                                <div className="switch-dropdown-options text-left">
                                    {/* <a href={`https://www.konfhub.com`}><span className="d-flex align-items-center"> <img src={KonfHub} className="me-3" alt="" /> KonfHub</span> </a> */}
                                    <Link target='_blank' href={`https://quiz.konfhub.com`}>
                                        <span className="d-flex align-items-center">
                                            <img src={QuizHub} className="me-2" alt="" /> QuizHub
                                        </span>
                                    </Link>
                                    <Link target='_blank' href={certhubUrl}>
                                        <span className="d-flex align-items-center">
                                            <img
                                                src={CertHub}
                                                style={{ marginRight: "10px" }}
                                                alt=""
                                            />{" "}
                                            CertHub
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                    <style>
                        {`
                        .switch-container {
                            height: 40px;
                        }
                        .switch-img-container {
                            height: 42px;
                            width: 42px;
                            border-radius: 100%;
                            overflow: hidden;
                            // border: 1px solid #fff;
                            background: #fff;
                            box-shadow:1px 1px 10px  #0002;
                        }

                        .switch-overlay {
                            display:none;
                            top: 0;
                            background: #000;
                            opacity: 0.3;
                            border-radius: 100%;
                        }

                        .switch-img-container:hover .switch-overlay {
                            display:block;
                            cursor: pointer;
                        }

                        .arrow-up {
                            width: 0; 
                            height: 0; 
                            margin-top: -50px !important;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            position: absolute;
                            top: 87px;
                            right: 12px;
                            border-bottom: 16px solid white;
                        }
                        .switch-dropDown-container {
                            z-index: 1030;
                            width: 140px;
                            background: #fff;
                            right: 0px;
                            border-radius: 3px;
                            top: 48px;
                            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                        }
                       
                        .switch-dropdown-options > * {
                            display: block;
                            width: 100%;
                            padding: 8px 0px 8px 6px;
                            border-radius:2px;
                            font-size: 16px;
                            color: #2D2D2D;
                            font-family: Nunito;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0em;
                            text-align: left;
                            text-decoration: none;
                        }

                        .switch-dropdown-options > *:hover {
                            background: #80747C33;
                            color: #2D2D2D;
                            cursor:pointer
                        }

                        .switchEvent-pad {
                            padding-bottom: 0.8rem !important;
                        }
                        `}
                    </style>
                </div>
            )}
        </>
    );
};

export default SwitchApplications;